/* ========================================================================
 * Apricot's Table Modules
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/table.scss";
import "../scss/includes/custom-scrollbar.scss";

// javaScript
import Utils from "./CBUtils";

// ------------------------------------  Responsive Table

/**
 * Custom Scrollbar
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.markup
 * @param {Boolean} data.scrollbarTop
 * @param {Boolean} data.scrollbarBottom
 * @param {Number} data.rowComplexity
 * @returns {{destroy: Function}}
 */
const customScrollbar = (data = {}) => {
  const defaultData = {
    elem: null,
    markup: true,
    scrollbarTop: true,
    scrollbarBottom: true,
    rowComplexity: 2,
  };
  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let plugin = {};

  plugin.elem = null;
  plugin.parent = null;
  plugin.container = null;
  plugin.width = null;
  plugin.rowComplexity = data.rowComplexity;
  //List of all scrollbars
  plugin.hScrollbars = [];

  const init = () => {
    elem.customScrollbar = "cb";

    if (elem.tagName !== "TABLE") return false;

    let elemId = Utils.attr(elem, "id")
      ? Utils.attr(elem, "id")
      : Utils.uniqueID(10, "customScrollbar_");
    Utils.attr(elem, "id", elemId);

    plugin.elem = elem;
    plugin.parent = elem.parentNode;
    plugin.id = elemId;

    if (data.scrollbarTop || data.scrollbarBottom) {
      if (data.markup) {
        buildScrollbar();
      } else {
        plugin.container = plugin.parent.parentNode;
      }

      if (data.scrollbarTop) {
        plugin.hScrollbarTop = new Scrollbar(plugin, new HSizing(), "top");
        plugin.hScrollbars.push(scrollbarObj("top", plugin.hScrollbarTop));

        plugin.hScrollbarTop.resize();
      }

      if (data.scrollbarBottom) {
        plugin.hScrollbarBottom = new Scrollbar(plugin, new HSizing(), "bottom");
        plugin.hScrollbars.push(scrollbarObj("bottom", plugin.hScrollbarBottom));

        plugin.hScrollbarBottom.resize();
      }

      initKeyboardScrolling();
    }
  };

  const scrollbarObj = (position, barObj) => {
    return {
      position: position,
      bar: barObj,
    };
  };
  const buildScrollbar = () => {
    const scrollable = document.createElement("DIV");
    Utils.addClass(scrollable, "cb-scrollbar-container");

    const scrollbarTop = document.createElement("DIV");
    Utils.addClass(scrollbarTop, "cb-scrollbar");

    const scrollbarBottom = document.createElement("DIV");
    Utils.addClass(scrollbarBottom, "cb-scrollbar");

    Utils.wrap(plugin.parent, scrollable);
    plugin.container = plugin.parent.parentNode;

    //Add top scrollbar
    if (data.scrollbarTop) {
      Utils.addClass(scrollbarTop, "cb-top");
      const tmp = document.createElement("DIV");
      Utils.addClass(tmp, "cb-scrollbar-thumb");
      scrollbarTop.appendChild(tmp);

      plugin.container.insertBefore(scrollbarTop, plugin.parent);
    }

    //Add bottom scrollbar
    if (data.scrollbarBottom) {
      Utils.addClass(scrollbarBottom, "cb-bottom");

      const tmp = document.createElement("DIV");
      Utils.addClass(tmp, "cb-scrollbar-thumb");
      scrollbarBottom.appendChild(tmp);

      plugin.container.appendChild(scrollbarBottom);
    }
  };
  const initKeyboardScrolling = () => {
    const _this = plugin;
    const browser = Utils.browser();

    plugin.elementKeydown = function (event) {
      let bars = {};

      if (browser.msie) {
        for (bars in plugin.hScrollbars) {
          _this.hScrollbars[bars].bar.keyScroll(event);
        }
      } else {
        if (document.activeElement === _this.container) {
          for (bars in plugin.hScrollbars) {
            _this.hScrollbars[bars].bar.keyScroll(event);
          }
        }
      }
    };

    Utils.attr(plugin.container, "tabindex", "-1");
    plugin.container.addEventListener("keydown", plugin.elementKeydown);
  };

  const Scrollbar = function (scrollable, sizing, position) {
    this.scrollable = scrollable;
    this.sizing = sizing;
    this.position = position;

    this.scrollbar = this.sizing.scrollbar(this.scrollable, position); //scrollbar, top|bottom
    this.thumb = this.scrollbar.querySelector(".cb-scrollbar-thumb"); //thumb

    this.setScrollPosition(0, 0);
    this.resize();

    this.initMouseMoveScrolling();
    this.initMouseWheelScrolling();
    this.initTouchScrolling();
    this.initMouseClickScrolling();
    this.initWindowResize();
  };

  Scrollbar.prototype = {
    resize: function (keepPosition) {
      var calculatedHeight = Utils.outerHeight(this.scrollable.elem) + 2;

      //Adjust height
      this.scrollable.parent.style.height = calculatedHeight + "px";

      this.sizing.size(this.scrollable.parent, this.sizing.size(this.scrollable.container));
      this.viewPortSize = this.sizing.size(this.scrollable.parent);
      this.overviewSize = this.sizing.size(this.scrollable.elem);

      // in some browsers(safari), outerWidth of the table is not calculated correctly
      if (parseInt(this.calculateTableWidth() - this.overviewSize) > 10) {
        this.overviewSize = this.calculateTableWidth();
      }

      this.ratio = this.viewPortSize / this.overviewSize;
      this.sizing.size(this.scrollable.parent, this.viewPortSize);
      this.sizing.size(this.scrollbar, this.viewPortSize);

      this.thumbSize = this.calculateThumbSize();
      this.sizing.size(this.thumb, this.thumbSize);

      this.maxThumbPosition = this.calculateMaxThumbPosition();
      this.maxOverviewPosition = this.calculateMaxOverviewPosition();

      this.enabled = this.overviewSize > this.viewPortSize;

      if (this.scrollPercent === undefined) {
        this.scrollPercent = 0.0;
      }

      if (this.enabled) {
        this.rescroll(keepPosition);
      } else {
        this.setScrollPosition(0, 0);
      }

      Utils.toggleDisplay(this.scrollbar, this.enabled);

      Utils.toggleClass(this.scrollable.container, "active", this.enabled);
      if (Utils.hasClass(this.scrollable.container, "active")) {
        Utils.attr(this.scrollable.container, "tabIndex", "0");
        Utils.attr(this.scrollable.container, "aria-label", "scrolling table");
        Utils.attr(this.scrollable.container, "role", "region");
      } else {
        Utils.removeAttr(this.scrollable.container, "tabIndex");
        Utils.removeAttr(this.scrollable.container, "aria-label");
        Utils.removeAttr(this.scrollable.container, "role");
      }
    },

    calculateViewPortSize: function () {
      var elementSize = this.sizing.size(this.scrollable.container);
      if (elementSize > 0 && !this.maxSizeUsed) {
        this.viewPortSize = elementSize;
        this.maxSizeUsed = false;
      } else {
        var maxSize = this.sizing.maxSize(this.scrollable.container);
        this.viewPortSize = Math.min(maxSize, this.overviewSize);
        this.maxSizeUsed = true;
      }
    },

    calculateTableWidth: function () {
      var width = 0,
        childrenWidth = 0,
        rows = [];

      if (plugin.width !== null) return plugin.width;

      // in some browsers, outerWidth of the table is not calculated correctly

      Array.from(plugin.elem.querySelectorAll("tr")).forEach((element, index) => {
        if (index === parseInt(plugin.rowComplexity, 10)) {
          return false;
        }

        childrenWidth = 0;
        Array.from(element.querySelectorAll("th, td")).forEach((element, index) => {
          childrenWidth += parseInt(Utils.outerWidth(element), 10);
        });

        rows.push(childrenWidth);
      });

      width = Math.max.apply(Math, rows);

      return width;
    },

    adjustCaption: function (type, width) {
      const caption = this.scrollable.elem.querySelector("caption");

      if (caption) {
        if (type) {
          caption.style.width = width + 1 + "px"; //border included
        } else {
          caption.style.width = "auto";
        }
      }
    },

    calculateThumbSize: function () {
      var size = this.ratio * this.viewPortSize;

      return Math.max(size, this.sizing.minSize(this.thumb));
    },

    initMouseMoveScrolling: function () {
      var _this = this;
      this.thumb.addEventListener("mousedown", function (event) {
        if (_this.enabled) {
          _this.startMouseMoveScrolling(event);
        }
      });

      this.documentMouseup = function (event) {
        _this.stopMouseMoveScrolling(event);
      };

      document.addEventListener("mouseup", this.documentMouseup);
      this.documentMousemove = function (event) {
        _this.mouseMoveScroll(event);
      };

      document.addEventListener("mousemove", this.documentMousemove);
      this.thumb.click(function (event) {
        event.stopPropagation();
      });
    },

    initMouseWheelScrolling: function () {
      var _this = this;

      this.scrollable.container.addEventListener(
        "mousewheel",
        function (event, delta, deltaX, deltaY) {
          if (_this.enabled) {
            if (_this.mouseWheelScroll(deltaX, deltaY)) {
              event.stopPropagation();
              // event.preventDefault();
            }
          }
        },
        { passive: true }
      );
    },

    initTouchScrolling: function () {
      if (document.addEventListener) {
        var _this = this;
        this.elementTouchstart = function (event) {
          if (_this.enabled) _this.startTouchScrolling(event);
        };

        this.scrollable.container.addEventListener("touchstart", this.elementTouchstart, {
          passive: true,
        });

        this.documentTouchmove = function (event) {
          _this.touchScroll(event);
        };

        document.addEventListener("touchmove", this.documentTouchmove, { passive: true });
        this.elementTouchend = function (event) {
          _this.stopTouchScrolling(event);
        };

        this.scrollable.container.addEventListener("touchend", this.elementTouchend, {
          passive: true,
        });
      }
    },

    initMouseClickScrolling: function () {
      var _this = this;
      this.scrollbarClick = function (event) {
        _this.mouseClickScroll(event);
      };

      this.scrollbar.click(this.scrollbarClick);
    },

    initWindowResize: function () {
      var _this = this;
      this.windowResize = function () {
        plugin.elem.style.width = null;
        _this.resize();
      };
      window.addEventListener("resize", this.windowResize);
    },

    isKeyScrolling: function (key) {
      return this.keyScrollDelta(key) !== null;
    },

    keyScrollDelta: function (key) {
      for (var scrollingKey in this.sizing.scrollingKeys)
        if (scrollingKey === key) return this.sizing.scrollingKeys[key](this.viewPortSize);
      return null;
    },

    startMouseMoveScrolling: function (event) {
      this.mouseMoveScrolling = true;
      const html = document.querySelector("html");
      Utils.addClass(html, "not-selectable");
      this.setUnselectable(html, "on");
      this.setScrollEvent(event);
    },

    stopMouseMoveScrolling: function () {
      this.mouseMoveScrolling = false;
      const html = document.querySelector("html");
      Utils.removeClass(html, "not-selectable");
      this.setUnselectable(html, null);
    },

    setUnselectable: function (element, value) {
      if (Utils.attr(element, "unselectable") !== value) {
        Utils.attr(element, "unselectable", value);
        const tmp = element.querySelector(":not(input)");
        Utils.attr(tmp, "unselectable", value);
      }
    },

    mouseMoveScroll: function (event) {
      if (this.mouseMoveScrolling) {
        var delta = this.sizing.mouseDelta(this.scrollEvent, event);

        this.scrollThumbBy(delta);
        this.setScrollEvent(event);

        //we have more than one scrollbar
        if (this.hasOtherScrollbarObj) {
          var bar = this.otherScrollableObj();
          if (!Utils.isEmptyObject(bar)) {
            bar.scrollThumbBy(delta);
            bar.setScrollEvent(event);
          }
        }
      }
    },

    hasOtherScrollbarObj: function () {
      if (this.scrollable.hScrollbars.length > 1) {
        return true;
      } else {
        return false;
      }
    },

    otherScrollableObj: function () {
      for (var bar in this.scrollable.hScrollbars) {
        if (this.scrollable.hScrollbars[bar].position !== this.position) {
          return this.scrollable.hScrollbars[bar].bar;
        }
      }
      return {};
    },

    startTouchScrolling: function (event) {
      if (event.touches && event.touches.length === 1) {
        this.setScrollEvent(event.touches[0]);
        this.touchScrolling = true;
        event.stopPropagation();
      }
    },

    touchScroll: function (event) {
      if (this.touchScrolling && event.touches && event.touches.length === 1) {
        var delta = -this.sizing.mouseDelta(this.scrollEvent, event.touches[0]);
        var scrolled = this.scrollOverviewBy(delta);
        if (scrolled) {
          event.stopPropagation();
          event.preventDefault();
          this.setScrollEvent(event.touches[0]);
        }
      }
    },

    stopTouchScrolling: function (event) {
      this.touchScrolling = false;
      event.stopPropagation();
    },

    mouseWheelScroll: function (deltaX, deltaY) {
      deltaY = 0;
      var delta = -this.sizing.wheelDelta(deltaX) * 40;
      if (delta !== 0) return this.scrollOverviewBy(delta);
    },

    mouseClickScroll: function (event) {
      var delta = this.viewPortSize - 20;
      if (
        event["page" + this.sizing.scrollAxis()] <
        this.thumb.offset()[this.sizing.offsetComponent()]
      )
        // mouse click over thumb
        delta = -delta;
      this.scrollOverviewBy(delta);

      //we have more than one scrollbar
      if (this.hasOtherScrollbarObj) {
        var bar = this.otherScrollableObj();
        if (!Utils.isEmptyObject(bar)) {
          bar.scrollOverviewBy(delta);
        }
      }
    },

    keyScroll: function (event) {
      var keyDown = event.which;

      if (this.enabled && this.isKeyScrolling(keyDown)) {
        if (this.scrollOverviewBy(this.keyScrollDelta(keyDown))) event.preventDefault();
      }
    },

    scrollThumbBy: function (delta) {
      var thumbPosition = this.thumbPosition();
      thumbPosition += delta;
      thumbPosition = this.positionOrMax(thumbPosition, this.maxThumbPosition);
      var oldScrollPercent = this.scrollPercent;
      this.scrollPercent = thumbPosition / this.maxThumbPosition;
      var overviewPosition = (thumbPosition * this.maxOverviewPosition) / this.maxThumbPosition;

      this.setScrollPosition(overviewPosition, thumbPosition);
      if (oldScrollPercent !== this.scrollPercent) {
        this.triggerCustomScroll(oldScrollPercent);
        return true;
      } else {
        return false;
      }
    },

    scrollOverviewTo: function (overviewPosition) {
      overviewPosition = this.positionOrMax(overviewPosition, this.maxOverviewPosition);
      var oldScrollPercent = this.scrollPercent;
      this.scrollPercent = overviewPosition / this.maxOverviewPosition;
      var thumbPosition = this.scrollPercent * this.maxThumbPosition;

      this.setScrollPosition(overviewPosition, thumbPosition);
      if (oldScrollPercent !== this.scrollPercent) {
        this.triggerCustomScroll(oldScrollPercent);
        return true;
      } else {
        return false;
      }
    },

    thumbPosition: function () {
      const position = Utils.position(this.thumb);
      return position[this.sizing.offsetComponent()];
    },

    scrollOverviewBy: function (delta) {
      var overviewPosition = this.overviewPosition() + delta;
      return this.scrollOverviewTo(overviewPosition);
    },

    overviewPosition: function () {
      const position = Utils.position(this.scrollable.elem);
      return -position[this.sizing.offsetComponent()];
    },

    positionOrMax: function (p, max) {
      if (p < 0) return 0;
      else if (p > max) return max;
      else return p;
    },

    triggerCustomScroll: function (oldScrollPercent) {
      var event = new CustomEvent("apricot_customScroll", {
        scrollAxis: this.sizing.scrollAxis(),
        direction: this.sizing.scrollDirection(oldScrollPercent, this.scrollPercent),
        scrollPercent: this.scrollPercent * 100,
      });
      this.scrollable.container.dispatchEvent(event);
    },

    rescroll: function (keepPosition) {
      var overviewPosition = 0,
        thumbPosition = 0;

      if (keepPosition) {
        overviewPosition = this.positionOrMax(this.overviewPosition(), this.maxOverviewPosition);
        this.scrollPercent = overviewPosition / this.maxOverviewPosition;

        thumbPosition = this.scrollPercent * this.maxThumbPosition;
        this.setScrollPosition(overviewPosition, thumbPosition);
      } else {
        thumbPosition = this.scrollPercent * this.maxThumbPosition;
        overviewPosition = this.scrollPercent * this.maxOverviewPosition;
        this.setScrollPosition(overviewPosition, thumbPosition);
      }
    },

    setScrollPosition: function (overviewPosition, thumbPosition) {
      const tmpAttr = this.sizing.offsetComponent();

      this.thumb.style[tmpAttr] = thumbPosition + "px";
      this.scrollable.elem.style[tmpAttr] = -overviewPosition + "px";
    },

    setScrollPositionWithAnimation: function (overviewPosition, thumbPosition) {
      var thumbAnimationOpts = {};
      var overviewAnimationOpts = {};
      thumbAnimationOpts[this.sizing.offsetComponent()] = thumbPosition + "px";
      overviewAnimationOpts[this.sizing.offsetComponent()] = -overviewPosition + "px";
    },

    calculateMaxThumbPosition: function () {
      return this.sizing.size(this.scrollbar) - this.thumbSize;
    },

    calculateMaxOverviewPosition: function () {
      var calculatedWidth = this.calculateTableWidth(),
        actualWidth = this.sizing.size(this.scrollable.elem),
        maxPosition = 0;

      // in some browsers(safari), outerWidth of the table is not calculated correctly
      if (parseInt(calculatedWidth - actualWidth) > 10) {
        maxPosition = calculatedWidth - this.sizing.size(this.scrollable.parent);
        this.adjustCaption(true, calculatedWidth);
      } else {
        maxPosition =
          this.sizing.size(this.scrollable.elem) - this.sizing.size(this.scrollable.parent);
        this.adjustCaption(false);
      }

      return maxPosition;
    },

    setScrollEvent: function (event) {
      var attr = "page" + this.sizing.scrollAxis();
      if (!this.scrollEvent || this.scrollEvent[attr] !== event[attr])
        this.scrollEvent = {
          pageX: event.pageX,
          pageY: event.pageY,
        };
    },
  };

  const HSizing = function () {};
  HSizing.prototype = {
    scrollingKeys: {
      37: function () {
        return -10; //arrow left
      },
      39: function () {
        return 10; //arrow right
      },
    },

    size: function ($el, arg) {
      if (arg) {
        $el.style.width = Math.round(arg) + "px";

        return Math.round(arg);
      } else {
        return Utils.width($el);
      }
    },

    minSize: function ($el) {
      return parseInt($el.style.minWidth) || 0;
    },

    scrollbar: function ($el, position) {
      return $el.container.querySelector(".cb-scrollbar.cb-" + position);
    },

    mouseDelta: function (event1, event2) {
      return event2.pageX - event1.pageX;
    },

    offsetComponent: function () {
      return "left";
    },

    wheelDelta: function (deltaX) {
      return deltaX;
    },

    scrollAxis: function () {
      return "X";
    },

    scrollDirection: function (oldPercent, newPercent) {
      return oldPercent < newPercent ? "right" : "left";
    },
  };

  const destroy = () => {
    if (elem.customScrollbar === "cb") {
      elem.customScrollbar = null;
    }

    Utils.removeClass(plugin.container, "cb-scrollbar");
    Utils.unwrap(plugin.parent);

    plugin.parent.style.width = "";
    plugin.parent.style.height = "";

    // only remove ID if the plugin has added it
    const tmpId = Utils.attr(plugin.elem, "id");
    if (tmpId.indexOf("customScrollbar") >= 0) {
      Utils.removeAttr(plugin.elem, "id");
    }
  };

  if (elem.customScrollbar !== "cb") {
    init();
  }

  return {
    destroy: destroy,
  };
};

/**
 * Simple Scrollbar
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @returns {{adjustScrollBar: Function}}
 * @returns {{destroy: Function}}
 */
const simpleScrollbar = (data = {}) => {
  const defaultData = {
    elem: null,
  };
  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let resizeTimeout = null;
  let parent = elem.parentNode;

  const init = () => {
    elem.simpleScrollbar = "cb";

    if (elem.tagName !== "TABLE") return false;
    Utils.addClass(parent, "cb-table-scroll");

    adjustScrollBar();
    window.addEventListener("resize", resizeThrottler, false);
  };

  const adjustScrollBar = () => {
    const pw = Utils.outerWidth(parent);
    const tw = Utils.outerWidth(elem);

    const ph = Utils.outerHeight(parent);
    const th = Utils.outerHeight(elem);

    if (tw > pw || th > ph) {
      Utils.attr(parent, "role", "region");
      Utils.attr(parent, "aria-label", "scrolling table");
      Utils.attr(parent, "tabindex", "0");
    } else {
      Utils.removeAttr(parent, "tabindex");
      Utils.removeAttr(parent, "aria-label");
      Utils.removeAttr(parent, "role");
    }
  };

  const resizeThrottler = () => {
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(function () {
        resizeTimeout = null;
        adjustScrollBar();
      }, 500);
    }
  };

  const destroy = () => {
    if (elem.simpleScrollbar === "cb") {
      elem.simpleScrollbar = null;
      Utils.removeClass(parent, "cb-table-scroll");
      window.removeEventListener("resize", resizeThrottler, false);
      resizeTimeout = null;
    }
  };

  if (elem.simpleScrollbar !== "cb") {
    init();
  }

  return {
    destroy: destroy,
    adjustScrollBar: adjustScrollBar,
  };
};

/**
 * Mobile Friendly Table
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Array} data.breakpoint
 * @param {Array} data.attr
 * @param {Function} data.onBuild
 * @param {Function} data.onShow
 * @param {Function} data.onHide
 * @returns {{destroy: Function}}
 */
const mobileTable = (data = {}) => {
  const defaultData = {
    elem: null,
    breakpoint: ["xs"],
    attr: ["id", "headers", "class"],
    onBuild: null,
    onShow: null,
    onHide: null,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let mTbl = null;
  let parent = Utils.parent(elem);

  // cell array
  let cArr = [];
  // row array
  let rArr = [];

  let viewport = Utils.viewport().prefix;
  const init = () => {
    elem.mobileTbl = "cb";

    if (elem.tagName !== "TABLE") return false;

    buildMobileTbl();
    checkLayout();

    Utils.breakpoints();
    document.addEventListener("apricot_breakpointChange", breakpointChange);
  };

  const checkLayout = () => {
    if (data.breakpoint.includes(viewport)) {
      showMobileTbl();
    } else {
      hideMobileTbl();
    }
  };

  const breakpointChange = (e) => {
    const data = e.data;
    viewport = data.prefix;

    checkLayout();
  };

  // mode: 0, ID
  // mode: 1, headers
  const fixDuplicateId = (value, attr) => {
    let attrValue = "";

    switch (attr) {
      case "id":
        attrValue = `mTbl_${value}`;
        break;
      case "headers":
        const arrValue = value.split(" ");
        const arr = arrValue.map((val) => {
          return `mTbl_${val}`;
        });

        attrValue = arr;
        break;
      default:
        attrValue = value;
    }

    return attrValue;
  };

  const pushObjToArr = (arr, key, val) => {
    const newArr = arr.filter((obj) => obj.key !== key);

    const obj = {};
    obj.key = key;
    obj.value = val;
    newArr.push(obj);

    return newArr;
  };

  const buildMobileTbl = () => {
    mTbl = document.createElement("TABLE");
    Utils.addClass(mTbl, "cb-table");
    Utils.addClass(mTbl, "cb-table-mobile");

    // gather data
    const tbody = document.createElement("TBODY");
    elem.querySelectorAll("thead > tr:not(.cb-table-sort-row) td, thead > tr:not(.cb-table-sort-row) th").forEach((node) => {
      const obj = {};

      Array.prototype.forEach.call(data.attr, (attr) => {
        if (Utils.attr(node, attr)) {
          obj[attr] = fixDuplicateId(Utils.attr(node, attr), attr);
        }
      });

      obj.th = node.tagName === "TH";
      obj.html = node.innerHTML;

      cArr.push(obj);
    });

    elem.querySelectorAll("tbody tr").forEach((tr) => {
      const arr = [];
      tr.querySelectorAll("td, th").forEach((node) => {
        const obj = {};

        Array.prototype.forEach.call(data.attr, (attr) => {
          if (Utils.attr(node, attr)) {
            obj[attr] = fixDuplicateId(Utils.attr(node, attr), attr);
          }
        });

        obj.th = node.tagName === "TH";
        obj.html = node.innerHTML;
        arr.push(obj);
      });

      rArr.push(arr);
    });

    // generate mobile table
    let rowCount = 0;
    let idArr = [];
    let replacementArr = [];

    for (var i in rArr) {
      for (var j in cArr) {
        const tr = document.createElement("TR");

        // First Cell
        const thNode = document.createElement("TH");
        Utils.attr(thNode, "scope", "row");

        if (!Utils.isBlank(cArr[j].html)) {
          thNode.innerHTML = cArr[j].html;
        }

        Array.prototype.forEach.call(data.attr, (attr) => {
          if (attr === "id") {
            let idVal = cArr[j][attr];
            if (idVal) {
              if (idArr.includes(idVal)) {
                // change id
                idVal = `${idVal}_${rowCount}`;
                replacementArr = pushObjToArr(replacementArr, cArr[j][attr], idVal);
              } else {
                idArr.push(idVal);
              }
            }

            Utils.attr(thNode, attr, idVal);
          } else if (attr === "headers") {
            if (cArr[j][attr]) {
              let headerId = cArr[j][attr].join(" ");
              let newHeader = "";
              Array.prototype.forEach.call(cArr[j][attr], (val) => {
                const obj = replacementArr.find((obj) => obj.key === val);
                if (obj) {
                  newHeader += `${obj.value} `;
                } else {
                  newHeader += `${val} `;
                }
              });

              if (newHeader !== "") {
                newHeader = newHeader.slice(0, -1);
                headerId = newHeader;
              }

              Utils.attr(thNode, attr, headerId);
            }
          } else {
            Utils.attr(thNode, attr, cArr[j][attr]);
          }
        });
        Utils.append(tr, thNode);

        // ---------  All the remaining ones
        const node = rArr[i][j].th ? document.createElement("TH") : document.createElement("TD");
        if (!Utils.isBlank(rArr[i][j].html)) {
          node.innerHTML = rArr[i][j].html;
        }

        Array.prototype.forEach.call(data.attr, (attr) => {
          if (rArr[i][j][attr]) {
            if (attr === "id") {
              let idVal = rArr[i][j][attr];
              if (idArr.includes(idVal)) {
                // change id
                idVal = `${idVal}_${rowCount}`;
                replacementArr = pushObjToArr(replacementArr, rArr[i][j][attr], idVal);
              } else {
                idArr.push(idVal);
              }

              Utils.attr(node, attr, idVal);
            } else if (attr === "headers") {
              if (rArr[i][j][attr]) {
                let headerId = rArr[i][j][attr].join(" ");
                let newHeader = "";
                Array.prototype.forEach.call(rArr[i][j][attr], (val) => {
                  const obj = replacementArr.find((obj) => obj.key === val);
                  if (obj) {
                    newHeader += `${obj.value} `;
                  } else {
                    newHeader += `${val} `;
                  }
                });

                if (newHeader !== "") {
                  newHeader = newHeader.slice(0, -1);
                  headerId = newHeader;
                }

                Utils.attr(node, attr, headerId);
              }
            } else {
              Utils.attr(node, attr, rArr[i][j][attr]);
            }
          }
        });

        Utils.append(tr, node);

        if (j === cArr.length - 1) {
          Utils.addClass(tr, "cb-last-row");
        }
        Utils.append(tbody, tr);
      }
      rowCount++;
    }

    Utils.append(mTbl, tbody);

    if (Utils.hasClass(parent, "cb-table-responsive")) {
      Utils.insertAfter(parent, mTbl);
    } else {
      Utils.insertAfter(elem, mTbl);
    }

    if (data.onBuild) {
      data.onBuild(elem, mTbl);
    }
  };

  const showMobileTbl = () => {
    if (mTbl) {
      Utils.addClass(elem, "cb-hidden");
      Utils.removeClass(mTbl, "cb-hidden");

      if (data.onShow) {
        data.onShow(elem, mTbl);
      }
    }
  };

  const hideMobileTbl = () => {
    if (mTbl) {
      Utils.removeClass(elem, "cb-hidden");
      Utils.addClass(mTbl, "cb-hidden");

      if (data.onHide) {
        data.onHide(elem, mTbl);
      }
    }
  };

  const destroy = () => {
    if (elem.mobileTbl === "cb") {
      elem.mobileTbl = null;
    }

    Utils.remove(mTbl);
    Utils.removeClass(elem, "cb-hidden");
  };

  if (elem.mobileTbl !== "cb") {
    init();
  }

  return {
    destroy: destroy,
  };
};

/**
 * Print Friendly Table
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @returns {{destroy: Function}}
 */
const printTable = (data = {}) => {
  const defaultData = {
    elem: null,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;

  const init = () => {
    elem.printTable = "cb";

    if (elem.tagName !== "TABLE") return false;

    let elemId = Utils.attr(elem, "id") ? Utils.attr(elem, "id") : Utils.uniqueID(5, "apricot_");
    Utils.attr(elem, "id", elemId);

    buildPrintTbl();
  };

  const buildPrintTbl = () => {
    Utils.addClass(elem, "cb-table-print");

    const thEls = elem.querySelectorAll("thead th, thead td");
    const tdLabels = Array.from(thEls).map((el) => el.innerText);

    elem.querySelectorAll("tbody tr").forEach((tr) => {
      Array.from(tr.children).forEach((th, ndx) => th.setAttribute("data-cb-label", tdLabels[ndx]));
      Array.from(tr.children).forEach((td, ndx) => td.setAttribute("data-cb-label", tdLabels[ndx]));
    });
  };

  const destroy = () => {
    if (elem.mobileTbl === "cb") {
      elem.mobileTbl = null;
    }
    elem.querySelectorAll("tbody tr").forEach((tr) => {
      Array.from(tr.children).forEach((td) => td.removeAttribute("data-cb-label"));
    });
  };

  if (elem.printTable !== "cb") {
    init();
  }

  return {
    destroy: destroy,
  };
};

/**
 * Sticky Colum/Row Table
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Boolean} data.header
 * @param {Boolean} data.column
 * @param {Number} data.columns
 * @param {Number} data.zIndex
 * @returns {{destroy: Function}}
 */
const stickyTable = (data = {}) => {
  const defaultData = {
    elem: null,
    header: true,
    column: false,
    columns: 1,
    zIndex: 1,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let table = null;
  let thead = null;
  let tbody = null;

  const header = data.header;
  const column = data.column;

  let resizeTimeout = null;
  let ths = [];

  const init = () => {
    elem.stickyTable = "cb";

    if (!header && !column) return;

    table = elem.querySelector("table");
    thead = table.querySelector("thead");
    tbody = table.querySelector("tbody");

    Utils.addClass(elem, "cb-active-sticky");

    // make sure table scroll are A11Y
    simpleScrollbar({
      elem: table,
    });

    reAlignTbl();

    //  events
    window.addEventListener("resize", resizeThrottler, false);
    elem.addEventListener("scroll", scrollAdj);
  };

  const scrollAdj = () => {
    if (header) {
      thead.style.transform = `translate3d(0,${elem.scrollTop}px,0)`;
      if (elem.scrollTop > 0) {
        Utils.addClass(thead, "cb-active-header");
      } else {
        Utils.removeClass(thead, "cb-active-header");
      }
    } else {
      thead.style.transform = `translate3d(0,0,0)`;
    }

    if (column) {
      const hTransform = `translate3d(${elem.scrollLeft}px,0,0)`;
      let selector = "";
      for (let i = 1; i < data.columns + 1; i++) {
        selector += `tr > th:nth-child(${i}), tr > td:nth-child(${i}),`;
      }
      selector = selector.slice(0, -1);
      thead.querySelectorAll(selector).forEach((th) => {
        th.style.transform = hTransform;
      });
      tbody.querySelectorAll(selector).forEach((td) => {
        td.style.transform = hTransform;
      });
      if (elem.scrollLeft > 0) {
        Utils.addClass(thead, "cb-active-column");
        Utils.addClass(tbody, "cb-active-column");
      } else {
        Utils.removeClass(thead, "cb-active-column");
        Utils.removeClass(tbody, "cb-active-column");
      }
    }
  };

  const resetTable = () => {
    thead.style.position = "";
    thead.style.top = "";
    thead.style.left = "";
    thead.style.zIndex = "";

    if (column) {
      tbody.setAttribute("style", "");
      thead.style.width = "";

      tbody.querySelectorAll("tr").forEach((tr) => {
        tr.setAttribute("style", "");
        tr.querySelectorAll("th", "td").forEach((td) => {
          td.style.width = "";
          td.style.position = "";
          td.style.left = "";
          Utils.removeClass(td, "cb-sticky-cell");
        });
      });
    }

    ths = [];
    thead.querySelectorAll("th").forEach((th) => {
      ths.push(th);
      Utils.removeClass(th, "cb-sticky-cell");
      th.style.display = "";
      th.style.width = "";
      th.style.position = "";
      th.style.top = "";
      th.style.left = "";
    });
  };

  const reAlignTbl = () => {
    // reset
    resetTable();

    // Set
    const thStyles = ths.map((th) => {
      const rect = th.getBoundingClientRect();
      const style = document.defaultView.getComputedStyle(th, "");
      return {
        boundingWidth: rect.width,
        boundingHeight: rect.height,
        width: parseInt(style.width, 10),
        paddingLeft: parseInt(style.paddingLeft, 10),
      };
    });

    const totalWidth = thStyles.reduce((sum, cur) => {
      return sum + cur.boundingWidth;
    }, 0);

    let stickyAdj = 0;
    if (column) {
      for (let i = 0; i < data.columns; i++) {
        stickyAdj += thStyles[i].boundingWidth;
      }
      tbody.style.display = "block";
      tbody.style.width = `${totalWidth}px`;
      thead.style.width = `${totalWidth - stickyAdj}px`;
      thead.style.left = `${stickyAdj}px`;
    }

    // Position thead
    thead.style.position = "absolute";
    thead.style.top = "0";
    thead.style.zIndex = data.zIndex;

    ths.forEach((th, i) => {
      th.style.width = `${thStyles[i].width}px`;
    });

    if (column) {
      let leftAdj = 0;
      ths.forEach((th, i) => {
        if (i < data.columns) {
          if (i === 0) {
            leftAdj = stickyAdj;
          } else {
            let tmpAdj = 0;
            for (let j = i; j < data.columns; j++) {
              tmpAdj += thStyles[j].boundingWidth;
            }
            leftAdj = tmpAdj;
          }
          Utils.addClass(th, "cb-sticky-cell");
          th.style.position = "absolute";
          th.style.top = "0";
          th.style.left = `-${leftAdj}px`;

          if (i === data.columns - 1) {
            Utils.addClass(th, "cb-sticky-cell-last");
          }
        }
      });
    }

    // Set margin-top for tbody - the fixed header is displayed in this margin
    if (column) {
      // based on the height of the header
      tbody.style.marginTop = `${thStyles[0].boundingHeight}px`;
      tbody.querySelectorAll("tr").forEach((tr) => {
        tr.style.display = "block";
        tr.style.paddingLeft = `${stickyAdj}px`;

        tr.querySelectorAll("th , td").forEach((td, i) => {
          td.style.width = `${thStyles[i].width}px`;

          if (i < data.columns) {
            Utils.addClass(td, "cb-sticky-cell");
            td.style.position = "absolute";
            if (i === 0) {
              td.style.left = "0";
            } else {
              let tmpAdj = 0;
              for (let j = 0; j < i; j++) {
                tmpAdj += thStyles[j].boundingWidth;
              }
              td.style.left = `${tmpAdj}px`;
            }
          }
          if (i === data.columns - 1) {
            Utils.addClass(td, "cb-sticky-cell-last");
          }
        });
      });
    }
  };

  const resizeThrottler = () => {
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(function () {
        resizeTimeout = null;

        reAlignTbl();
      }, 500);
    }
  };

  const destroy = () => {
    if (elem.stickyTable === "cb") {
      elem.stickyTable = null;
      Utils.removeClass(elem, "cb-active-sticky");
      ths = [];

      resetTable();
      window.removeEventListener("resize", resizeThrottler, false);
      elem.removeEventListener("scroll", scrollAdj);
    }
  };

  if (elem.stickyTable !== "cb") {
    init();
  }

  return {
    destroy: destroy,
  };
};

/**
 * Stripe effect for Expandable Table
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Element} data.reverse
 * @returns {{destroy: Function}}
 */
const expandableStripe = (data = {}) => {
  const defaultData = {
    elem: null,
    striped: true,
    reverse: false,
  };
  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let rows = [];
  let expRows = [];

  const init = () => {
    if (elem.tagName !== "TABLE") return false;

    elem.querySelectorAll("tbody tr").forEach((tr) => {
      Utils.removeClass(tr, "cb-no-stripe");
      Utils.removeClass(tr, "cb-stripe");

      if (!Utils.hasClass(tr, "cb-table-expandable-row")) {
        rows.push(tr);
      } else {
        expRows.push(tr);
      }
    });

    if (data.striped) {
      addStripe();
    } else {
      removeStripe();
    }
  };

  const addStripe = () => {
    let ext = [];
    const class1 = data.reverse ? "cb-no-stripe" : "cb-stripe";
    const class2 = data.reverse ? "cb-stripe" : "cb-no-stripe";

    Array.prototype.forEach.call(rows, (row, index) => {
      if (Utils.hasClass(row, "cb-table-selected")) {
        ext.push({ id: index, class: "cb-table-selected" });
      } else if (Utils.hasClass(row, "cb-table-highlight")) {
        ext.push({ id: index, class: "cb-table-highlight" });
      } else if (index % 2) {
        Utils.addClass(row, class1);
      } else {
        Utils.addClass(row, class2);
      }
    });
    Array.prototype.forEach.call(expRows, (row, index) => {
      const obj = ext.find((r) => r.id === index);
      if (obj) {
        Utils.addClass(row, obj.class);
      } else if (index % 2) {
        Utils.addClass(row, class1);
      } else {
        Utils.addClass(row, class2);
      }
    });
  };

  const removeStripe = () => {
    elem.querySelectorAll("tbody tr").forEach((tr) => {
      Utils.removeClass(tr, "cb-no-stripe");
      Utils.removeClass(tr, "cb-stripe");
      if (Utils.hasClass(tr, "cb-table-expandable-row")) {
        Utils.removeClass(tr, "cb-table-selected");
        Utils.removeClass(tr, "cb-table-highlight");
      }
    });
  };

  init();
};

export default {
  customScrollbar,
  simpleScrollbar,
  mobileTable,
  printTable,
  stickyTable,
  expandableStripe,
};
