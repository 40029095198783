/* ========================================================================
 * Apricot's Toast
 * ======================================================================== */

// SCSS
import "../scss/includes/apricot-base.scss";
import "../scss/includes/toast.scss";

// javaScript
import Utils from "./CBUtils";

/**
 * Toast
 *
 * @export
 * @param {Object} data
 * @param {Element} data.elem
 * @param {Number} data.timeout
 * @param {Boolean} data.alternative
 * @param {Boolean} data.success
 * @param {Boolean} data.successIcon
 * @param {Boolean} data.warning
 * @param {Boolean} data.warningIcon
 * @param {String} data.message
 * @param {String|Array} data.style
 * @param {String} data.ctaLabel
 * @param {String} data.ctaAriaLabel
 * @param {Function} data.ctaCallback
 * @param {Boolean} data.closeBtn
 * @param {String} data.closeBtnLabel
 * @param {Function} data.onDismiss
 * @param {Boolean} data.autoClose
 * @param {Boolean} data.escClose
 * @param {Boolean} data.bottom
 * @param {Number} data.offsetTop
 * @param {Number} data.offsetBottom
 * @param {Number} data.max
 * @param {Boolean} data.react
 * @returns {{destroy: Function}}
 */

const Toast = (data = {}) => {
  const defaultData = {
    elem: null,
    timeout: 5000,
    alternative: false,
    success: false,
    successIcon: false,
    warning: false,
    warningIcon: false,
    message: null,
    style: null,
    ctaLabel: null,
    ctaAriaLabel: null,
    ctaCallback: null,
    closeBtn: true,
    closeBtnLabel: "Close alert message",
    onDismiss: null,
    autoClose: true,
    escClose: true,
    bottom: false,
    offsetTop: 0,
    offsetBottom: 16,
    max: 3,
    react: false,
  };

  data = {
    ...defaultData,
    ...data,
  };

  let elem = data.elem;
  let toastNode = null;
  let hasNode = false;
  let toastContainer = null;
  let timeout = data.timeout;
  let timeoutId = 0;
  let transitionTime = 90;

  const react = data.react;

  const init = () => {
    toastContainer = document.querySelector(".cb-toast-container");
    if (Utils.elemExists(elem)) {
      hasNode = true;
      if (elem.toastPlugin === "cb") {
        return;
      } else {
        elem.toastPlugin = "cb";
        if (react) {
          toastNode = elem;
        } else {
          toastNode = elem.cloneNode(true);
        }
        toastNode.querySelector(".cb-btn-close") &&
          toastNode.querySelector(".cb-btn-close").addEventListener("click", (e) => {
            e.preventDefault();

            triggerDismiss(false);
          });

        toastNode.querySelector(".cb-toast-action") &&
          toastNode.querySelector(".cb-toast-action").addEventListener("click", (e) => {
            e.preventDefault();

            data.ctaCallback && data.ctaCallback(e);
          });
      }
    } else {
      toastNode = createToast();
    }

    // There is no other toast
    if (!toastContainer && !react) {
      toastContainer = findContainer();
      if (data.bottom) {
        toastContainer.style.bottom = `${data.offsetBottom}px`;
        Utils.addClass(toastContainer, "cb-toast-container-bottom");
      } else {
        toastContainer.style.top = `${data.offsetTop}px`;
      }
    }

    if (data.escClose) {
      Utils.attr(document.getElementsByTagName("body")[0], "data-cb-esc", "true");
      closeOnEsc();
    }
    if (Utils.elemExists(toastNode.querySelector(".cb-toast-action"))) {
      if (timeout === 5000) {
        timeout = 10000;
      }
    }

    show();
  };

  const closeOnEsc = () => {
    document.addEventListener("keydown", escA11Y, true);
  };

  const escA11Y = (e) => {
    if (e.keyCode === 27) {
      if (Utils.elemExists(toastNode)) {
        triggerDismiss(false);
      }
    }
  };

  const createToast = () => {
    const toastNode = document.createElement("DIV");
    Utils.addClass(toastNode, "cb-toast");
    Utils.attr(toastNode, "role", "alert");

    if (data.alternative) {
      Utils.addClass(toastNode, "cb-toast-alternative");
    } else if (data.success) {
      Utils.addClass(toastNode, "cb-toast-success");
    } else if (data.warning) {
      Utils.addClass(toastNode, "cb-toast-warning");
    }

    data.style && Utils.addClass(toastNode, data.style);
    if (data.successIcon || data.warningIcon) {
      const span = document.createElement("SPAN");
      Utils.addClass(span, "cb-icon");
      if (data.successIcon) {
        Utils.addClass(span, "cb-check-fill");
      } else if (data.warningIcon) {
        Utils.addClass(span, "cb-exclamation-fill");
      }
      Utils.addClass(span, "cb-margin-right-8");
      const sr = document.createElement("SPAN");
      Utils.addClass(sr, "sr-only");
      sr.innerText = data.successIcon ? "success" : "warning";

      Utils.append(span, sr);
      Utils.append(toastNode, span);
    }

    const p = document.createElement("P");
    Utils.addClass(p, "cb-toast-msg");
    p.innerText = data.message;
    const msgId = Utils.uniqueID(5, "apricot_");
    Utils.attr(p, "id", msgId);

    Utils.append(toastNode, p);

    if (data.ctaLabel) {
      const a = document.createElement("A");
      Utils.addClass(a, "cb-toast-action");
      Utils.addClass(a, "cb-margin-left-16");
      Utils.attr(a, "href", "#");

      data.ctaAriaLabel && Utils.attr(a, "aria-label", data.ctaAriaLabel);
      a.innerText = data.ctaLabel;
      a.addEventListener("click", (e) => {
        e.preventDefault();

        data.ctaCallback && data.ctaCallback(e);
      });
      Utils.append(toastNode, a);

      Utils.attr(toastNode, "role", "alertdialog");
      Utils.attr(toastNode, "aria-describedby", msgId);
    }

    if (data.closeBtn) {
      const button = document.createElement("BUTTON");
      Utils.attr(button, "type", "button");
      Utils.addClass(button, [
        "cb-btn",
        "cb-btn-square",
        "cb-btn-greyscale",
        "cb-btn-close",
        "cb-margin-left-8",
      ]);

      const glyph = document.createElement("SPAN");
      Utils.addClass(glyph, "cb-icon");
      Utils.addClass(glyph, "cb-x-mark");
      Utils.attr(glyph, "aria-hidden", "true");
      Utils.append(button, glyph);

      const sr = document.createElement("SPAN");
      Utils.addClass(sr, "sr-only");
      sr.innerText = data.closeBtnLabel;
      Utils.append(button, sr);
      button.addEventListener("click", (e) => {
        e.preventDefault();
        triggerDismiss(false);
      });

      Utils.append(toastNode, button);
    }

    toastNode.id = Utils.uniqueID(5, "apricot_");

    return toastNode;
  };

  // toast will be added to this
  const findContainer = () => {
    let container = null;
    // create one
    container = document.createElement("DIV");
    Utils.addClass(container, "cb-toast-container");
    const body = document.getElementsByTagName("body")[0];
    Utils.append(body, container);

    return container;
  };

  const show = () => {
    // append toast to container
    if (hasNode) {
      Utils.attr(toastNode, "aria-hidden", "false");
    }
    Utils.addClass(toastNode, "cb-toast-enter");
    if (data.autoClose) {
      startTimeout();
    }

    // check is we already have a toast
    if (!react) {
      const toasts = toastContainer.querySelectorAll(".cb-toast");
      if (toasts.length > 0 && toasts.length <= data.max - 1) {
        toastContainer.insertBefore(toastNode, toasts[0]);
      } else if (toasts.length === 0) {
        toastContainer.appendChild(toastNode);
      }
    }

    setTimeout(() => {
      Utils.addClass(toastNode, "cb-toast-enter-active");
      setTimeout(() => {
        Utils.removeClass(toastNode, "cb-toast-enter");
        Utils.removeClass(toastNode, "cb-toast-enter-active");
        if (Utils.elemExists(toastNode.querySelector(".cb-toast-action"))) {
          toastNode.querySelector(".cb-toast-action").focus();
        }

        const event = new CustomEvent("apricot_toast_show");
        event.data = {
          elem: toastNode,
        };
        document.dispatchEvent(event);
      }, transitionTime);
    }, transitionTime);
  };

  const startTimeout = () => {
    clearTimeout(timeoutId);
    if (timeout >= 0) {
      timeoutId = setTimeout(() => triggerDismiss(true), timeout);
    }
  };

  // timeoutExpire: timeout or close/ESC button
  const triggerDismiss = (timeoutExpire) => {
    clearTimeout(timeoutId);

    removeToast();
    const event = new CustomEvent("apricot_toast_hide");
    event.data = {
      elem: toastNode,
    };
    document.dispatchEvent(event);

    data.onDismiss && data.onDismiss(timeoutExpire);
  };

  const removeToast = () => {
    Utils.addClass(toastNode, "cb-toast-exit");
    setTimeout(() => {
      Utils.addClass(toastNode, "cb-toast-exit-active");
      setTimeout(() => {
        if (elem) {
          elem.toastPlugin = null;
        }
        if (!react) {
          toastNode.remove();
        }
        toastNode = null;

        // close on ESC
        Utils.removeAttr(document.getElementsByTagName("body")[0], "data-cb-esc");
      }, transitionTime);
    }, transitionTime);
  };

  const hide = () => {
    triggerDismiss(false);
  };

  const destroy = () => {
    removeToast();
    clearTimeout(timeoutId);
  };

  init();
  return {
    hide: hide,
    destroy: destroy,
  };
};

export default Toast;
