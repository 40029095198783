/* ========================================================================
 * Apricot's Regular expressions Validation Module
 * ======================================================================== */

const RULES = {
  password:
    /^((?=.*[a-z])+(?=.*[A-Z])+(?=.*\d)+(?=.*[\^\$\*\.\[\]\{\}\?\(\)\-\"\!\@\#\%\/\\\,\>\<\'\:\;\|\_\~\`\+\=]))\S{9,30}$/,
  email:
    /^([a-zA-Z0-9"]{1}[a-zA-Z0-9^$*.\[\]\{\}\?\(\)\-\"&!@#%\/\,\\><\':;|_~\`+=]*[a-zA-Z0-9"]{1})@[a-zA-Z0-9^$*.\[\]\{\}\?\(\)\-"&!@#%\/\,\\><\':;|_~\`+=]*\.[a-zA-Z0-9-]+$/,
  alphanumeric: /^[a-zA-Z0-9-\/'\s]+$/,
  alphabetic: /^[a-zA-Z\/'\s]+$/,
  number: /^[0-9]+$/,
  address: /^[a-zA-Z0-9-'-.#\s]{1,50}$/,
  zipCode: /^\d{5}$/,
  zipCodeCanada: /^([A-Za-z]\d[A-Za-z][-]?\d[A-Za-z]\d)/,
  phone: /^\d{10}$/,
  phoneMask: /^\(\d{3}\)\s\d{3}-\d{4}$/,
  month: /^(0?[1-9]|1[012])$/,
  day: /^(0?[1-9]|1\d|2\d|3[01])$/,
  year: /^(([0-9]{2})|([1-9]{1}[0-9]{3}))$/,
  date: /^(0?[1-9]|1[012])[- \/.](0?[1-9]|1\d|2\d|3[01])[- \/.](([0-9]{2})|([1-9]{1}[0-9]{3}))$/,
  specialChar: /[\^\$\*\.\[\]\{\}\?\(\)\-\"\!\@\#\%\/\\\,\>\<\'\:\;\|\_\~\`\+\=]/,
  required: /^(\S+)$/,
};

// ------------------------------------  ERROR MESSAGES
const MESSAGES = {
  password: "Invalid. Please try your password again.",
  email:
    "Please enter a valid email address. Email address must contain at least two characters before the @ symbol and period.",
  required: "This field is required.",
  alphanumeric: "Please enter only alphanumeric values.",
  alphabetic: "Please enter only alphabetic values.",
  number: "Please enter only numeric values.",
  address: "Your address cannot exceed 50 characters.",
  zipCode: "Please enter a valid zip code.",
  phone: "Please enter a valid phone number.",
  month: "Please enter a valid month value.",
  day: "Please enter a valid day value.",
  year: "Please enter a valid year value.",
  date: "Please enter a valid date.",
};

/**
 * Validate Password (RULES.password)
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const password = (value) => {
  const validator = new RegExp(RULES.password);

  return validator.test(value);
};

/**
 * Validate Email (RULES.email)
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const email = (value) => {
  const emailValidator = new RegExp(RULES.email);
  const quoteValidator = {
    test: (value) => {
      const left = value.split("@")[0];
      const quotePattern = new RegExp(/"/g);
      const localPattern = new RegExp(/"@/g);

      if (value.indexOf('""') !== -1) {
        return false;
      }
      if ((value.match(quotePattern) || []).length === 1) {
        return false;
      }
      if (left.indexOf('"') === 0 && (value.match(localPattern) || []).length <= 0) {
        return false;
      }

      return true;
    },
  };

  return emailValidator.test(value) && quoteValidator.test(value);
};

/**
 * Validate Address (RULES.address)
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const address = (value) => {
  const validator = new RegExp(RULES.address);

  return validator.test(value);
};

/**
 * Validate Zip Code (RULES.zipCode)
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const zipCode = (value) => {
  const validator = new RegExp(RULES.zipCode);

  return validator.test(value);
};

/**
 * Validate Phone (RULES.phone)
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const phone = (value) => {
  const validator = new RegExp(RULES.phone);

  return validator.test(value);
};

/**
 * Validate Date (RULES.date)
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const date = (value) => {
  const validator = new RegExp(RULES.date);

  return validator.test(value);
};

/**
 * Validate Date (RULES.day)
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const day = (value) => {
  const validator = new RegExp(RULES.day);

  return validator.test(value);
};

/**
 * Validate Month (RULES.month)
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const month = (value) => {
  const validator = new RegExp(RULES.month);

  return validator.test(value);
};

/**
 * Validate Year (RULES.year)
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const year = (value) => {
  const validator = new RegExp(RULES.year);

  return validator.test(value);
};

/**
 * Validate Alphanumeric (RULES.alphanumeric)
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const alphanumeric = (value) => {
  const validator = new RegExp(RULES.alphanumeric);

  return validator.test(value);
};

/**
 * Validate Alphabetic (RULES.alphabetic)
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const alphabetic = (value) => {
  const validator = new RegExp(RULES.alphabetic);

  return validator.test(value);
};

/**
 * Validate Number
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const number = (value) => {
  const validator = new RegExp(RULES.number);

  return validator.test(value);
};

// ------------------------------------  Utility

/**
 * Validate Special Characters (RULES.specialChar)
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const validateSpecialChar = (value) => {
  const validator = new RegExp(RULES.specialChar);

  return validator.test(value);
};

/**
 * Validate min-max range
 *
 * @export
 * @param {String} value
 * @param {Number} min
 * @param {Number} max
 * @returns {Boolean}
 */
const validateLength = (value, min, max) => {
  return value.length >= min && value.length <= max;
};

/**
 * Validate ONE Lowercase
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const validateLowercase = (value) => {
  const validator = new RegExp(/[a-z]/);

  return validator.test(value);
};

/**
 * Validate All Lowercase
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const validateAllLowercase = (value) => {
  const validator = new RegExp(/[a-z]+$/);

  return validator.test(value);
};

/**
 * Validate ONE Uppercase
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const validateUppercase = (value) => {
  const validator = new RegExp(/[A-Z]/);

  return validator.test(value);
};

/**
 * Validate ALL Uppercase
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const validateAllUppercase = (value) => {
  const validator = new RegExp(/[A-Z]+$/);

  return validator.test(value);
};

/**
 * Validate Whitespace
 *
 * @export
 * @param {String} value
 * @returns {Boolean}
 */
const validateWhitespace = (value) => {
  const validator = new RegExp(/\s/);

  return !validator.test(value);
};

export default {
  RULES,
  MESSAGES,
  password,
  email,
  address,
  zipCode,
  phone,
  date,
  year,
  month,
  day,
  alphanumeric,
  alphabetic,
  number,
  validateSpecialChar,
  validateLength,
  validateLowercase,
  validateAllLowercase,
  validateUppercase,
  validateAllUppercase,
  validateWhitespace,
};
